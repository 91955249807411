<template>
    <div class="class-list">
        <div class="button_top">
            <div class="faultBtn1">
                <div v-bind:class="this.up === '01' ? 'left' : 'right'" @click="updownAccountClick('active')">
                    批量上账
                </div>
                <div v-bind:class="this.up === '02' ? 'left' : 'right'" @click="updownAccountClick('before')">
                    批量下账
                </div>
            </div>
            <div v-if="this.up == '01'">
                <el-button size="small" type="warning" icon="el-icon-plus"
                    @click="openUpAccountDialog">创建批量上账任务</el-button>
            </div>
            <div v-if="this.up == '02'">
                <el-button size="small" type="warning" icon="el-icon-plus"
                    @click="opendownAccountDialog">创建批量下账任务</el-button>
            </div>
        </div>
        <div class="button_top">
            <div class="radiobtn">
                <div class="faultBtn">
                    <div v-bind:class="record === '01' ? 'left' : 'right'" @click="recordClick('active')">
                        导入记录
                    </div>
                    <div v-bind:class="record === '02' ? 'left' : 'right'" @click="recordClick('before')">
                        {{ this.up === '01' ? "上账记录" : "下账记录" }}
                    </div>
                </div>
            </div>
        </div>
        <GlobalChunk icon="search" title="筛选查询">
            <div slot="filter">
                <Form :batchNo="batchNo" :up="up" :record="record" ref="uplaodform"></Form>
            </div>
            <redbageTable :up="up" :record="record" ref="upDownLoadList" @childInput="childInput" />
        </GlobalChunk>
        <!-- 导入线下打款订单弹框 -->
        <el-dialog :title="buttonname" :visible.sync="importVisible" :close-on-click-modal="false"
            class="import-business-dialog" width="540px" @close="exportinDialogClosed">
            <el-form ref="importForm" :model="importForm" :rules="importRules" style="margin-top: 20px;">
                <el-form-item label="商户类型">
                    <el-radio-group v-model="importForm.shoptype" disabled>
                        <el-radio label="merchant">回收商</el-radio>
                        <el-radio label="company">门店商</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item class="el_from" label="上账类型" prop="upPeople">
                    <el-select v-model="importForm.upPeople" placeholder="请选择类型" filterable clearable>
                        <el-option v-for="item in upPeopleList" :key="item.key" :label="item.value"
                            :value="item.key"></el-option>
                    </el-select>
                </el-form-item>
                <div style="margin-bottom: 20px;">
                    <span style="color: #FF687B;margin-right: 4px;">*</span>导入上账商户及金额
                </div>
                <div style="margin-bottom: 20px;color: #FF687B">
                    说明:下载表格模板填写后导入,商户名称需要与系统名称对应
                </div>
                <el-button type="success" icon="el-icon-download" size="small" @click="download"
                    style="margin-bottom: 20px;">下载数据模板</el-button>
                <el-form-item label="导入备注" prop="aprReason" class="fileName1">
                    <el-input placeholder="请输入原因说明(限200字)" v-model.trim="importForm.aprReason" maxlength="200" clearable
                        type="textarea" style="width: 226%;">
                    </el-input>
                </el-form-item>
                <!-- 上传 -->
                <el-form-item label="表格上传" prop="fileName" class="fileName fileName12">
                    <el-input v-model="importForm.fileName" @clear="fileClear" :disabled="true" size="medium" clearable>
                    </el-input>
                    <div style="float: right; position: relative">
                        <el-button size="medium" type="warning" icon="el-icon-upload2">上传表格</el-button>
                        <input accept=".xls, .xlsx" ref="fileUpload" @change="onUploadList" type="file" style="
                  opacity: 0;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  cursor: pointer;
                " />
                    </div>
                    <div style="color: #ff687b;margin-left: 76px;">
                        仅支持.xls及.xlsx格式,单次上传大小不超过5M
                    </div>
                </el-form-item>

                <div class="inquery" v-if="isImported && importList.length > 0">共计查询到 <span style="color:#0981FF;">{{
                    importList.length
                        }}</span>
                    个商户{{ buttonname == "创建批量上账任务" ? "待上账" : "待下账" }}</div>
            </el-form>
            <span slot="footer">
                <el-button @click.native="exportinDialogClosed">取消</el-button>
                <el-button type="primary" :loading="importLoading" @click.native="importSuccess">确定</el-button>
            </span>
        </el-dialog>
        <!--批量下账-->
        <el-dialog title="创建批量下账任务" :visible.sync="downimportVisible" :close-on-click-modal="false"
            class="import-business-dialog" width="540px" @close="exportinDialogClosed12">
            <el-form ref="importForm1" :model="importForm1" :rules="importRules1" style="margin-top: 20px;">
                <el-form-item label="商户类型">
                    <el-radio-group v-model="importForm1.shoptype" disabled>
                        <el-radio label="merchant">回收商</el-radio>
                        <el-radio label="company">门店商</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item class="el_from" label="下账类型" prop="upPeople">
                    <el-select v-model="importForm1.upPeople" placeholder="请选择类型" filterable clearable>
                        <el-option v-for="item in upPeopleList1" :key="item.key" :label="item.value"
                            :value="item.key"></el-option>
                    </el-select>
                </el-form-item>
                <div style="margin-bottom: 20px;">
                    <span style="color: #FF687B;margin-right: 4px;">*</span>导入下账商户及金额
                </div>
                <div style="margin-bottom: 20px;color: #FF687B">
                    说明:下载表格模板填写后导入,商户名称需要与系统名称对应
                </div>
                <el-button type="success" icon="el-icon-download" size="small" @click="download1"
                    style="margin-bottom: 20px;">下载数据模板</el-button>
                <el-form-item label="导入备注" prop="aprReason" class="fileName1">
                    <el-input placeholder="请输入原因说明(限200字)" v-model.trim="importForm1.aprReason" maxlength="200"
                        clearable type="textarea" style="width: 226%;">
                    </el-input>
                </el-form-item>
                <!-- 上传 -->
                <el-form-item label="表格上传" prop="fileName" class="fileName fileName12">
                    <el-input v-model="importForm1.fileName" @clear="fileClear" :disabled="true" size="medium"
                        clearable>
                    </el-input>
                    <div style="float: right; position: relative">
                        <el-button size="medium" type="warning" icon="el-icon-upload2">上传表格</el-button>
                        <input accept=".xls, .xlsx" ref="fileUpload1" @change="onUploadList1" type="file" style="
                  opacity: 0;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  cursor: pointer;
                " />
                    </div>
                    <div style="color: #ff687b;margin-left: 76px;">
                        仅支持.xls及.xlsx格式,单次上传大小不超过5M
                    </div>
                </el-form-item>

                <div class="inquery" v-if="isImported1 && importList1.length > 0">共计查询到 <span style="color:#0981FF;">{{
                    importList1.length
                        }}</span>
                    个商户{{ "待下账" }}</div>
            </el-form>
            <span slot="footer">
                <el-button @click.native="exportinDialogClosed12">取消</el-button>
                <el-button type="primary" :loading="importLoading1" @click.native="importSuccess2">确定</el-button>
            </span>
        </el-dialog>
        <!-- 是否存在最近导入上账数据弹框 -->
        <el-dialog title="操作提示" :visible.sync="isExistRecentVisible" :close-on-click-modal="false"
            class="import-business-dialog" width="540px" @close="exportinDialogClosed1">
            <div>
                <span style="color: rgb(9, 129, 255);" v-if="enterpriseList.length > 0 && enterpriseList[0]">{{
                    enterpriseList[0]}}、</span><span style="color: rgb(9, 129, 255);"
                    v-if="enterpriseList.length > 0 && enterpriseList[0]">{{ enterpriseList[1]
                    }}等</span><span
                    style="color: rgb(9, 129, 255);">{{ enterpriseList.length}}个商户</span><span>在短期内有相同金额的上账记录，请确认是否继续操作？</span>
            </div>
            <span slot="footer">
                <el-button @click.native="exportinDialogClosed1">取消</el-button>
                <el-button type="primary" :loading="importLoading" @click.native="importSuccess1">确定</el-button>
            </span>
        </el-dialog>
        <!-- 是否存在最近导入下账数据弹框 -->
        <el-dialog title="操作提示" :visible.sync="isExistRecentVisible1" :close-on-click-modal="false"
            class="import-business-dialog" width="540px" @close="exportinDialogClosed34">
            <div>
                <span style="color: rgb(9, 129, 255);" v-if="enterpriseList1.length > 0 && enterpriseList1[0]">{{
                    enterpriseList1[0] }}、</span><span style="color: rgb(9, 129, 255);"
                    v-if="enterpriseList1.length > 0 && enterpriseList1">{{ enterpriseList1[1]
                    }}等</span><span
                    style="color: rgb(9, 129, 255);">{{ enterpriseList1.length}}个商户</span><span>在短期内有相同金额下账记录，请确认是否继续操作？</span>
            </div>
            <span slot="footer">
                <el-button @click.native="exportinDialogClosed34">取消</el-button>
                <el-button type="primary" :loading="importLoading" @click.native="importSuccess3">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import _api from "@/utils/request";
import { _http } from "../../../utils/request/bill";
export default {
    name: "class-list",
    components: {
        Form: () => import("./form"),
        redbageTable: () => import("./table"),
    },
    data() {
        return {
            up: '01',
            record: '01',
            buttonname: "创建批量上账任务",
            importVisible: false,
            importForm: { shoptype: "merchant", upPeople: "", fileName: "", aprReason: "" },
            importForm1: { shoptype: "merchant", upPeople: "", fileName: "", aprReason: "" },
            importRules: {
                fileName: [
                    {
                        required: true,
                        message: "仅支持.xls及.xlsx格式,单次上传大小不超过5M",
                        trigger: "blur",
                    },
                ],
                upPeople: [{
                    required: true,
                    message: "请选择类型",
                    trigger: "change",
                }],
                aprReason: [{
                    required: true,
                    message: "请输入原因说明(限200字)",
                    trigger: "change",
                }]

            },
            importRules1: {
                fileName: [
                    {
                        required: true,
                        message: "仅支持.xls及.xlsx格式,单次上传大小不超过5M",
                        trigger: "blur",
                    },
                ],
                upPeople: [{
                    required: true,
                    message: "请选择类型",
                    trigger: "change",
                }],
                aprReason: [{
                    required: true,
                    message: "请输入原因说明(限200字)",
                    trigger: "change",
                }]

            },
            importBtnLoading: false,
            isImported: false,//控制是否上传了数据,
            importList: [],
            importLoading: false,
            importLoading1: false,
            upPeopleList: [],
            upPeopleList1: [],
            paramsExcel: {},
            isExistRecentVisible: false,
            isExistRecentVisible1: false,
            fd: null,
            downimportVisible: false,
            importList1: [],
            isImported1: false,
            paramsExcel1: {},
            batchNo: "",
            enterpriseList:[],
            enterpriseList1:[]
        };
    },
    created() {
        this.$store.commit("tagsView/SEACH_PARAMS", {})
    },
    methods: {
        updownAccountClick(type) {
            if (type === 'active') {
                this.up = '01'
                this.record = '01'
                this.buttonname = "创建批量上账任务"
            } else {
                this.up = '02'
                this.record = '01'
                this.buttonname = "创建批量下账任务"
            }
            this.batchNo = ""
        },
        recordClick(type) {
            console.log(type, 9999)
            if (type === 'active') {
                this.record = '01'
            } else {
                this.record = '02'
            }
            this.batchNo = ""
        },
        //打开创建上下账任务弹框
        openUpAccountDialog() {
            console.log(1111)
            this.importVisible = true
            // this.isExistRecentVisible=true
            let params = {
                opt: this.buttonname == "创建批量上账任务" ? '00' : '01',
                accountType: this.buttonname == "创建批量上账任务" ? 'merchant' : '01'
            };
            _api.getUpDownTypeList(params).then((res) => {
                console.log(res)
                res.data.map((item, index) => {
                    if (item.value == "差异款上账") {
                        res.data.splice(index, 1)
                    }
                })
                console.log(res.data, 9999)
                if (res.code === 1) {
                    this.upPeopleList = res.data;
                }
            });
        },
        //打开创建下账任务弹框
        opendownAccountDialog() {
            this.downimportVisible = true
            let params = {
                opt: this.accountType = '01',
                accountType: "merchant"
            };
            _api.getUpDownTypeList(params).then((res) => {
                console.log(res, 99999)
                if (res.code === 1) {
                    this.upPeopleList1 = res.data;
                }
            });

        },
        //关闭创建下账任务弹框
        exportinDialogClosed12() {
            this.downimportVisible = false
            this.importList1 = []
            this.isImported1 = false
            this.importForm1.shoptype = "merchant"
            this.importForm1.aprReason = ""
            this.importForm1.fileName = "";
            this.$refs.fileUpload1.value = "";
            this.paramsExcel1 = {};
            this.fd1 = null;
            this.$refs.importForm1.resetFields()
        },
        //关闭创建上下账任务弹框
        exportinDialogClosed() {
            this.importVisible = false
            this.importList = []
            this.isImported = false
            this.importForm.shoptype = "merchant"
            this.importForm.aprReason = ""
            this.importForm.fileName = "";
            this.$refs.fileUpload.value = "";
            this.paramsExcel = {};
            this.fd = null;
            this.fd1 = null;
            this.$refs.importForm.resetFields()
        },

        //关闭创建上账任务弹框
        exportinDialogClosed1() {
            this.isExistRecentVisible = false
        },
        //关闭创建下账任务弹框
        exportinDialogClosed34() {
            this.isExistRecentVisible1 = false
        },
        //下在数据模板
        download() {
            const link = document.createElement("a");
            if (this.buttonname == "创建批量上账任务") {
                link.href = "/static/导入上账数据模板.xlsx";
                link.setAttribute("download", "导入上账数据模板.xlsx");
            }
            if (this.buttonname == "创建批量下账任务") {
                link.href = "/static/导入下账数据模板.xlsx";
                link.setAttribute("download", "导入下账数据模板.xlsx");
            }
            document.body.appendChild(link);
            link.click();
        },
        //下账
        download1() {
            const link = document.createElement("a");
            link.href = "/static/导入下账数据模板.xlsx";
            link.setAttribute("download", "导入下账数据模板.xlsx");
            document.body.appendChild(link);
            link.click();
        },
        fileClear() {
            this.$refs.fileUpload.value = "";
            this.paramsExcel = {};
        },
        // 上传文件
        onUploadList(event, type) {
            console.log(event);
            if (!this.importForm.upPeople) {
              this.fileClear()
                return this.$message.error('上账类型不能为空')
            }
            if (!this.importForm.aprReason) {
                this.fileClear()
                return this.$message.error('导入备注不能为空')
            }
            if (!this.$refs.fileUpload.value) {
                this.fileClear()
                return this.$message.error('请先上传导入文件')
            }
            this.paramsExcel.file = event.target.files;
            this.paramsExcel.excelName = event.target.files[0].name;
            this.importForm.fileName = event.target.files[0].name;
            // fd.append("warehouseId", this.warehouseId); //仓库id
            this.fd = this.paramsExcel.file[0]
            const fd = new FormData();
            fd.append("accountType", this.importForm.shoptype)
            fd.append("file", this.fd)
            fd.append("isContinue", null)
            fd.append("opt", this.buttonname == "创建批量上账任务" ? "00" : "01")
            fd.append("typeCode", this.importForm.upPeople)
            fd.append("note", this.importForm.aprReason)
            _http.dispatchImport(fd).then(res => {
                console.log(res, 999)
                if (res.code === 1) {
                    if(res.data.list){
                            if(res.data.list.length>0){
                                this.importList = res.data.list
                            }
                            else{
                                this.importList = []
                            }
                    }
                    if(!res.data.list){
                        this.importList = []
                    }
                    if(res.data.enterpriseList){
                            if(res.data.enterpriseList.length>0){
                                this.enterpriseList = res.data.enterpriseList
                            }
                            else{
                                this.enterpriseList = []
                            }
                    }
                    if(!res.data.enterpriseList){
                        this.enterpriseList = []
                    }
                    this.isExistRecentVisible = res.data.isExistRecent
                    this.isImported = true
                    if(!res.data.isExistRecent){
                        this.$message({
                        type: "success",
                        message: res.msg || '上传成功',
                    });
                    }
                }
            }).finally(() => {
                this.importLoading = false
            });
        },
        // 上传文件
        onUploadList1(event, type) {
            console.log(1122)
            if (!this.importForm1.upPeople) {
                this.$refs.fileUpload1.value = "";
                this.paramsExcel1 = {};
                return this.$message.error('下账类型不能为空')
            }
            if (!this.importForm1.aprReason) {
                this.$refs.fileUpload1.value = "";
                this.paramsExcel1 = {};
                return this.$message.error('导入备注不能为空')
            }
            if (!this.$refs.fileUpload1.value) {
                this.$refs.fileUpload1.value = "";
                this.paramsExcel1 = {};
                return this.$message.error('请先上传导入文件')
            }
            console.log(event);
            this.paramsExcel1.file = event.target.files;
            this.paramsExcel1.excelName = event.target.files[0].name;
            this.importForm1.fileName = event.target.files[0].name;
            // fd.append("warehouseId", this.warehouseId); //仓库id
            this.fd1 = this.paramsExcel1.file[0]
            const fd1 = new FormData();
            fd1.append("accountType", this.importForm1.shoptype)
            fd1.append("file", this.fd1)
            fd1.append("isContinue", null)
            fd1.append("opt", "01")
            fd1.append("typeCode", this.importForm1.upPeople)
            fd1.append("note", this.importForm1.aprReason)
            _http.dispatchImport(fd1).then(res => {
                console.log(res, 999)
                if (res.code === 1) {
                    if(res.data.list){
                            if(res.data.list.length>0){
                                this.importList1 = res.data.list
                            }
                            else{
                                this.importList1 = []
                            }
                    }
                    if(!res.data.list){
                        this.importList1 = []
                    }
                    if(res.data.enterpriseList){
                            if(res.data.enterpriseList.length>0){
                                this.enterpriseList1 = res.data.enterpriseList
                            }
                            else{
                                this.enterpriseList1 = []
                            }
                    }
                    if(!res.data.isExistRecent){
                        this.enterpriseList1 = []
                    }
                    this.isExistRecentVisible1 = res.data.isExistRecent
                    this.isImported1 = true
                    if(!res.data.isExistRecent){
                        this.$message({
                        type: "success",
                        message: res.msg || '上传成功',
                    });
                    }
                }
            }).finally(() => {
                this.importLoading1 = false
            });
        },
        importSuccess() {
            if (!this.importForm.upPeople) {
                this.fileClear()
                return this.$message.error('上账类型不能为空')
            }
            if (!this.importForm.aprReason) {
                this.fileClear()
                return this.$message.error('导入备注不能为空')
            }
            if (!this.$refs.fileUpload.value) {
                this.fileClear()
                return this.$message.error('请先上传导入文件')
            }
            this.importLoading = true
            const fd = new FormData();
            fd.append("accountType", this.importForm.shoptype)
            fd.append("file", this.fd)
            fd.append("isContinue", true)
            fd.append("opt", this.buttonname == "创建批量上账任务" ? "00" : "01")
            fd.append("typeCode", this.importForm.upPeople)
            fd.append("note", this.importForm.aprReason)
            _http.dispatchImport(fd).then(res => {
                console.log(res, 999)
                if (res.code === 1) {
                    this.$message({
                        type: "success",
                        message: res.msg || '操作成功',
                    });
                    this.isExistRecentVisible=false
                    this.importVisible = false
                    this.importList = []
                    this.isImported = false
                    this.importForm.shoptype = "merchant"
                    this.importForm.aprReason = ""
                    this.importForm.fileName = "";
                    this.$refs.fileUpload.value = "";
                    this.paramsExcel = {};
                    this.fd = null;
                    this.$refs.importForm.resetFields()
                    this.$refs.upDownLoadList.handleCurrentChange()
                }
            }).finally(() => {
                this.importLoading = false
            });
        },
        importSuccess1() {
            this.importSuccess()
        },
        importSuccess3() {
            this.importSuccess2()
        },
        importSuccess2() {
            if (!this.importForm1.upPeople) {
                this.$refs.fileUpload1.value = "";
                this.paramsExcel1 = {};
                return this.$message.error('下账类型不能为空')
            }
            if (!this.importForm1.aprReason) {
                this.$refs.fileUpload1.value = "";
                this.paramsExcel1 = {};
                return this.$message.error('导入备注不能为空')
            }
            if (!this.$refs.fileUpload1.value) {
                this.$refs.fileUpload1.value = "";
                this.paramsExcel1 = {};
                return this.$message.error('请先上传导入文件')
            }
            this.importLoading1 = true
            const fd1 = new FormData();
            fd1.append("accountType", this.importForm1.shoptype)
            fd1.append("file", this.fd1)
            fd1.append("isContinue", true)
            fd1.append("opt", "01")
            fd1.append("typeCode", this.importForm1.upPeople)
            fd1.append("note", this.importForm1.aprReason)
            _http.dispatchImport(fd1).then(res => {
                console.log(res, 999)
                if (res.code === 1) {
                    this.$message({
                        type: "success",
                        message: res.msg || '操作成功',
                    });
                    this.isExistRecentVisible1=false
                    this.downimportVisible = false
                    this.importList1 = []
                    this.isImported1 = false
                    this.importForm1.shoptype = "merchant"
                    this.importForm1.aprReason = ""
                    this.importForm1.fileName = "";
                    this.$refs.fileUpload1.value = "";
                    this.paramsExcel1 = {};
                    this.fd1 = null;
                    this.$refs.importForm1.resetFields()
                    this.$refs.upDownLoadList.handleCurrentChangedown()
                }
            }).finally(() => {
                this.importLoading = false
            });
        },
        childInput(obj) {
            console.log(obj, 123)
            this.up = obj.up
            this.record = obj.record
            this.batchNo = obj.query.batchNo
            // this.$router.push({path:obj.path,query:obj.query})
        }
    },
};
</script>
<style scoped lang="scss" ref="stylesheet/scss">
.class-list {
    .button_top {
        cursor: pointer;
        margin-bottom: 20px;
        ;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .faultBtn {
        width: 280px;
        height: 32px;
        background: #F9FBFD;
        border: 1px solid #C1D1FF;
        box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
        border-radius: 21px;
        display: flex;

        .left {
            text-align: center;
            color: white;
            width: 50%;
            height: 14px;
            font-size: 14px;
            font-family: FZLanTingHei-M-GBK;
            font-weight: 400;
            line-height: 30px;
            height: 30px;
            background: #0981FF;
            border-radius: 20px;
        }

        .right {
            text-align: center;
            color: #333;
            width: 50%;
            height: 14px;
            font-size: 14px;
            font-family: FZLanTingHei-M-GBK;
            font-weight: 400;
            line-height: 30px;
            height: 30px;
            border-radius: 20px;
        }
    }

    .faultBtn1 {
        width: 280px;
        height: 32px;
        display: flex;

        .left {
            position: relative;
            text-align: center;
            color: #0981FF;
            height: 14px;
            font-size: 16px;
            font-family: FZLanTingHei-M-GBK;
            font-weight: 700;
            line-height: 30px;
            height: 30px;
            border-radius: 20px;
            display: flex;

            &::before {
                content: "";
                width: 40px;
                height: 3px;
                border-radius: 1px;
                background-color: #5a8eff;
                position: absolute;
                top: 32px;
                left: 20%;
            }
        }

        .right {
            text-align: center;
            color: #333;
            height: 14px;
            font-size: 14px;
            font-family: FZLanTingHei-M-GBK;
            font-weight: 400;
            line-height: 30px;
            height: 30px;
            border-radius: 20px;
            display: flex;
        }
    }
}

.faultBtn1 div:nth-child(1) {
    margin-right: 20px;
}

.cance_tip {
    color: #ff8080;
    font-size: 14px;
    margin-bottom: 20px;
}

.fileName {
    /deep/.el-input {
        width: 260px;
    }

    /deep/.el-button {
        // border-radius: 10px;
        margin-right: 0px;
    }
}

.inquery {
    color: #4C84FF;
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
    margin-left: 67px;
}

.inquery::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background: #4C84FF;
    border-radius: 50%;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.fileName1 {
    display: flex;
    align-items: center;
}

.fileName12 {
    margin-bottom: 0 !important;
}
</style>